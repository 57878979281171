$(document).ready(function(){
    $('.parallax').parallax();

// function przewijania 

$('a').on('click', function(event) {
    if (this.hash !== "") {
        event.preventDefault();
        var hash = this.hash;
        $('html, body').animate({
            scrollTop: $(hash).offset().top
        },1200, 
                                function(){
            window.location.hash = hash;
        }
                               );
    }
});

  }); // function ready 